import '../../login/login';
import '../packet/styles/responsive.styl';
import './styles.styl';
import { trackPacketEvent } from '../utils/utils';
import { updateRequirements } from 'login.util';

$(document).ready(function() {
	window.updateRequirements = updateRequirements;
	const $passwordField1 = $('#p1');
	if ($passwordField1.length > 0) {
		$passwordField1.passwordValidate('#p2', updateRequirements).focus();
		$passwordField1.focus();
	}
});

/**
 * Check the upper and lower case requirements. If both are true, add the base color to the bullet
 *
 * @param {Boolean} upper
 * @param {Boolean} lower
 *
 * @returns {void}
 */
window.verifyUpperAndLower = function verifyUpperAndLower(upper, lower) {
	if (upper && lower) {
		$('#pass-upper-lower > span.passwordForm__requirements-bullet').addClass('baseBgColor');
		$('#pass-upper-lower > span.passwordForm__requirements-text').addClass('passwordForm__requirements-text--bold');
	}
};

/**
 * Reset the password for an onboarding user
 *
 * @param {string} email the email to send the reset instructions to
 *
 * @returns {void}
 */
window.resetOnboardingPassword = function resetOnboardingPassword(email) {
	$.post(
		'/self_onboarding/login.php',
		{
			email: email,
			reset_password_email: true
		},
		function(response) {
			if (response.success) {
				$('.flip-container').removeClass('flip');
				$('.forgot.fback').trigger('click');
				setMessage(response.message, 'success');
			} else {
				setMessage(response.error, 'error');
				$('#femail').val('').focus();
			}
		},
		'json'
	);
	return false;
};

$(document).ready(function() {
	$('.js-createAccountBtn').on('click', function() {
		trackPacketEvent(this);
	});
});
